export const SEO_CONFIG = {
  siteTitle: 'Free online Poll and Micro-survey tool - Pollme', // Site title.
  siteTitleAlt: 'Pollme', // Alternative site title for SEO.
  siteLogo: 'https://pollme.app/static/favicons/pollme_512_icon.png', // '/logos/logo-512x512.png', // Logo used for SEO and manifest.
  siteFav: 'https://pollme.app/static/favicons/pollme_icon_560.png',
  siteBanner: 'https://pollme.app/static/pollme_banner.png',
  siteSquareBanner: 'https://pollme.app/static/pollme_square.png',
  siteAuthor: 'CHAMLABS LLC',
  siteUrl: 'https://pollme.app', // Domain of your website without pathPrefix.
  siteDescription: `The easiest way to create free beautiful polls and micro-surveys on the internet. Complete No-Code solution. Share them wherever you want, integrate them on your website.`, // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml', // Path to the RSS file.
  siteFBAppID: '', // FB Application ID for using app insights
  FBPageURL: '',
  FBPageSlug: '',
  googleTagManagerID: 'GTM-P5SL26C', // google tag manager ID
  googleAnalyticsID: 'G-SLCLQF3KPF',
  googleAdSenseClientID: 'ca-pub-6660230237896908',
  // googleReCaptchaSiteKey: '6LdBsUAUAAAAAJ13aGwvSKX_ILEc7paJO9P-T1Qp',
  userEmail: 'hello@pollme.app', // Email used for RSS feed's author segment
  userTwitter: '@pollme_app',
  userLocation: 'Santiago, Chile', // User location to display in the author segment.
  themeColor: '#2372E8', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff' // Used for setting manifest background color.
};

export default SEO_CONFIG;

import styled, { css } from 'styled-components';
import { tint } from 'polished';
import { rotate, loadingSkeleton, borderDance } from './animations';

export const ViewWrapper = styled.div`
  min-height: 100vh;
  ${'' /* background-color: var(--white); */}
`;
export const PageWrapper = styled.div`
  min-height: 100vh;
  ${'' /* background-color: var(--background-alt); */}
  background-color: var(--blue-background-extra-light);
`;

export const SinglePageWrapper = styled.div``;

export const EmbedWrapper = styled.div`
  background-color: transparent;
`;

export const SingleSurveyWrapper = styled.div`
  min-height: 100vh;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 49vh;
    background-color: var(--muted);
  }
  .top-line {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--blue);
    z-index: 5;
  }
  .logo-wrapper {
    margin-top: 1.5rem;
    img {
      width: 200px;
      object-fit: contain;
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    .logo-wrapper {
      text-align: center;
    }
  }
`;

export const ContentWrapper = styled.div`
  padding: 3rem 4rem;
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    padding: 3rem 3rem;
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    padding: 3rem 2rem;
  }
`;

export const BlogContentWrapper = styled.div`
  padding: 1rem 4rem;
  padding-bottom: 6rem;
  .heading-search {
    display: grid;
    grid-template-columns: 65% auto;
    align-items: center;
    grid-gap: 1rem;
    .heading-text {
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: var(--muted-strong);
      font-weight: 500;
    }
  }
  .blog-search-wrapper {
    position: relative;
    width: 100%;
    input {
      display: block;
      width: 100%;
      margin: 0 auto;
      border-radius: var(--border-radius);
      border: var(--border);
      padding: 0.75rem;
      font-size: 1.1rem;
      color: var(--text-color);
      height: var(--item-height);
      ${({ errorState }) =>
        errorState &&
        css`
          border-color: var(--danger) !important;
        `}
    }
    svg {
      position: absolute;
      width: 1.875rem;
      height: 1.875rem;
      top: 0.85rem;
      right: 0.875rem;
      color: var(--muted-strong);
    }
  }
  .posts-list {
    padding: 0 2rem;
    .list-section {
      padding-top: 2rem;
      text-transform: uppercase;
      h3 {
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.lg}) {
    .heading-search {
      grid-template-columns: 50% 50%;
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    padding: 1rem 3rem;
    .heading-search {
      grid-template-columns: 1fr;
    }
    .posts-list {
      padding: 0 1rem;
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    padding: 1rem 2rem;
  }
`;

export const ProfilePageWrapper = styled.div`
  padding: 2rem 4rem;
  background-color: var(--blue-background-extra-light);
  .option-group {
    padding: 1.5rem 0 3rem 0;
    border-bottom: var(--border);
    &.last {
      border-bottom: 0;
    }
    .delete-option {
      padding: 1.5rem 0;
      button {
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        color: var(--muted-strong);
      }
    }
    .delete-confirm {
      padding-top: 1.5rem;
      button {
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        color: var(--white);
        background-color: var(--red);
        &:hover {
          background-color: var(--red-hover);
        }
      }
      .account-remove-tooltip {
        padding: 0.5rem;
        .tooltip-label {
          padding: 0.5rem;
          border-radius: var(--border-radius);
          background-color: var(--danger-background);
          color: var(--danger);
          font-weight: 700;
        }
        .tooltip-info-text {
          padding: 0.5rem;
        }
      }
      .remove-footer {
        padding: 0.5rem 0;
        .btn-cancel {
          width: 150px;
          margin-left: 5px;
          color: var(--muted-strong);
          background-color: var(--white);
          border: var(--border);
          &:hover {
            background-color: var(--white-hover);
          }
        }
      }
    }
    .subscriptions-list {
      .subscription-block {
        padding: 0.5rem 0;
        h3 {
          margin: 0;
        }
        .plan-title {
          font-family: var(--font-heading);
          font-weight: 600;
          padding: 0.25rem 0;
          span {
            font-size: 0.75rem;
            font-weight: 400;
          }
        }
        .plan-description {
          font-size: 0.875rem;
          line-height: 1.15rem;
        }
      }
    }
    .billing-option {
      padding: 1.5rem 0;
      button {
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        &:disabled {
          background-color: var(--white-hover);
          color: var(--muted-strong);
          cursor: not-allowed;
        }
        .updating {
          justify-content: center;
          align-items: center;
          animation: ${rotate} 2s linear infinite;
        }
      }
    }
    .user-info {
      position: relative;
      display: grid;
      grid-template-columns: 100px auto;
      grid-gap: 1rem;
      align-items: center;
      .user-image {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 0.5rem;
        position: relative;
        .user-pic {
          border-radius: 50%;
        }
        .edit-img-btn {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 2px solid var(--muted);
          outline: none;
          position: absolute;
          bottom: -5px;
          left: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            display: flex !important;
          }
          &:hover {
            border-color: var(--blue);
          }
        }
      }
      .user-contact {
        font-weight: 600;
        font-size: 1.15rem;
        .contact-label {
          padding: 0.25rem 0;
          .contact-edit {
            margin-left: 5px;
            button {
              background-color: transparent;
              color: var(--muted-strong);
              font-size: 0.875rem;
              outline: none;
              &:hover {
                color: inherit;
              }
              &.btn-primary {
                color: var(--white);
                background-color: var(--blue);
                &:hover {
                  background-color: var(--blue-hover);
                }
              }
            }
          }
        }
        .contact-provider {
          padding-top: 0.25rem;
          font-size: 0.875rem;
          color: var(--blue);
          a {
            font-size: 0.875rem;
            color: var(--blue);
            &:hover {
              color: var(--blue-hover);
              text-decoration: underline;
            }
          }
        }
      }
    }
    .input-group {
      padding-bottom: 1rem;
      .label {
        font-size: 0.875rem;
        font-weight: 600;
        color: var(--muted-strong);
        padding-bottom: 0.5rem;
        .label-actions {
          button {
            font-size: 0.7rem;
            margin-left: 5px;
            color: var(--muted-strong);
          }
        }
      }
      .input-wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: auto 150px;
        grid-gap: 0.5rem;
        align-items: center;
        input {
          display: block;
          width: 100%;
          margin: 0 auto;
          border-radius: var(--border-radius);
          border: var(--border);
          padding: 0.75rem;
          font-size: 1.1rem;
          color: var(--text-color);
          height: 32px;
          &:disabled {
            background-color: var(--white);
          }
        }
        button {
          height: 100%;
          width: 100%;
          border: var(--border);
          border-left: 0;
          background-color: var(--blue);
          color: var(--white);
          outline: none;
          &:hover {
            background-color: var(--blue-hover);
          }
        }
      }
    }
    .share-action {
      width: 100%;
      button {
        font-size: 0.875rem;
        padding: 0.5rem;
        height: 100%;
        width: 100%;
        border: 1px dashed var(--muted-strong);
        background-color: var(--white);
        outline: none;
        &:hover {
          background-color: var(--white-hover);
        }
        &:disabled {
          color: var(--muted-strong);
          background-color: var(--muted);
        }
      }
    }
    .share-link {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 64px;
      align-items: center;
      &.with-hide {
        grid-template-columns: 1fr 32px 150px;
      }
      input {
        display: block;
        width: 100%;
        margin: 0 auto;
        border-radius: var(--border-radius);
        border: var(--border);
        padding: 0.75rem;
        font-size: 1.1rem;
        color: var(--text-color);
        height: 32px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        &:disabled {
          background-color: var(--white);
        }
        &.blur-text {
          color: transparent;
          text-shadow: 3px 0 5px rgba(0, 0, 0, 0.5);
        }
      }
      button {
        height: 100%;
        width: 100%;
        border: var(--border);
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-color: var(--blue);
        color: var(--white);
        outline: none;
        &:hover {
          background-color: var(--blue-hover);
        }
        &.action-blur {
          background-color: var(--white);
          color: inherit;
          &:hover {
            background-color: var(--white-hover);
          }
          border-right: 0;
          border-radius: 0;
        }
      }
    }
  }

  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    padding: 2rem 3rem;
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    padding: 1.5rem 2rem;
  }
`;

export const MDXContentWrapper = styled.div`
  padding: 4rem 10rem 8rem 10rem;
  max-width: 1024px;
  margin: 0 auto;
  .heading {
    padding-bottom: 4rem;
  }
  h1 {
    margin: 0;
    font-size: 3.5rem;
    text-align: center;
    padding-bottom: 1rem;
    font-weight: 600;
  }
  .post-summary {
    text-align: center;
    font-size: 1.15rem;
    line-height: 1.25rem;
  }
  .post-info {
    text-align: center;
    color: var(--muted-strong);
    font-size: 0.875rem;
    p {
      margin: 0;
    }
  }
  .post-body {
    padding: 4rem 0;
    .wrapper-full {
      width: 100%;
      height: auto;
      img,
      video {
        width: 100%;
        height: auto;
      }
    }
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2rem;
  }
  p,
  ul,
  ol {
    line-height: 2rem;
    word-break: break-word;
  }
  a {
    color: var(--blue);
    &:hover {
      color: var(--blue-hover);
    }
  }
  img {
    object-fit: contain;
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.xl}) {
    padding: 4rem 8rem 8rem 8rem;
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.lg}) {
    padding: 4rem 5rem 8rem 5rem;
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    padding: 2rem 2.5rem 6rem 2.5rem;
    h1 {
      font-size: 3rem;
      text-align: center;
    }
    h2 {
      font-size: 2.25rem;
    }
    h3 {
      font-size: 1.875rem;
    }
    ul,
    ol {
      padding-inline-start: 2rem;
    }
  }
`;

export const StyledPageBanner = styled.div`
  padding: 0 4rem;
  display: grid;
  grid-template-columns: 45% auto;
  align-items: center;
  min-height: calc(100vh - 80px); /** remove 80px from nav */
  position: relative;
  overflow: hidden;
  .gradient-primary {
    background-color: var(--blue);
    background-image: linear-gradient(45deg, var(--blue), var(--indigo));
  }
  .gradient-text {
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
  .content-col {
    h1 {
      font-size: 6.5rem;
      line-height: 6rem;
      margin: 0;
      padding-bottom: 2rem;
      font-weight: 800;
      letter-spacing: 2px;
      /* Fallback: Set a background color. */
      background-color: var(--blue);
      background-image: linear-gradient(45deg, var(--blue), var(--indigo));
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
    }
    .lead {
      font-size: 2rem;
      line-height: 2.5rem;
      ${'' /* color: var(--muted-strong); */}
      opacity: 0.9;
      font-weight: 500;
      padding-bottom: 0.5rem;
    }
  }
  .hero-col {
    width: 100%;
    height: 100%;
    padding: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .img-wrapper {
      width: 450px;
      height: 450px;
      position: relative;
      img {
        object-fit: contain;
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.xxl}) {
    ${'' /* grid-template-columns: 50% auto; */}
    .content-col {
      h1 {
        font-size: 5.5rem;
        line-height: 5rem;
      }
      .lead {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.xl}) {
    grid-template-columns: 1fr;
    &::before {
      top: 25%;
      right: 0;
      width: 100%;
      bottom: 0;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: -25%;
    }
    .content-col {
      padding-top: 4rem;
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    padding: 0 3rem;
    &::before {
      top: 25%;
      right: 0;
      width: 100%;
      bottom: 0;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: -25%;
    }
    .content-col {
      h1 {
        font-size: 5rem;
        line-height: 4rem;
      }
    }
    .hero-col {
      padding: 2rem 0;
      .img-wrapper {
        width: 100%;
        height: 350px;
        position: relative;
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    padding: 0 2rem;
    .content-col {
      h1 {
        font-size: 4rem;
        line-height: 3.5rem;
        text-align: center;
      }
      .lead {
        font-size: 1.5rem;
        line-height: 2rem;
        text-align: center;
      }
    }
  }
`;

export const StyledSideNav = styled.div`
  padding: 1rem 1.5rem;
  width: 20%;
  background-color: var(--white);
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: scroll;
  box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.1);
  .menu-title {
    font-weight: 500;
    color: var(--muted-strong);
    font-size: 0.8rem;
    text-transform: uppercase;
  }
  .template-list {
    ul {
      list-style: none;
      padding: 0;
      padding-left: 0.5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0.5rem;
    }
    .link {
      font-size: 0.875rem;
      font-weight: 500;
      text-decoration: underline;
      a:hover {
        color: var(--blue);
      }
    }
    button {
      padding: 0;
      border: none;
      outline: none;
      color: var(--muted-strong);
      background-color: var(--white);
      &:hover {
        color: var(--text-color);
      }
      &[data-active='true'] {
        color: var(--blue);
      }
    }
  }

  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    display: none;
  }
`;

export const StyledTopNav = styled.div`
  ${'' /* height: 80px; */}
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid var(--border-color);
  .info-row {
    ${'' /* min-height: 45px; */}
    min-height: 60px;
    padding: 0.5rem 2.5rem;
    display: grid;
    grid-template-columns: 60% auto;
    grid-gap: 0.5rem;
    align-items: center;
    .nav-context {
      .nav-details-container {
        display: grid;
        grid-template-columns: 1fr;
        .poll-details {
          ${'' /* display: grid;
          grid-template-columns: 1fr 28px 2fr 28px; */}
          display: flex;
          overflow: hidden;
          ${'' /* margin-left: 0.5rem; */}
          align-items: center;
          flex-grow: 1;
        }
        .list-icon {
          color: var(--muted-strong);
          text-align: center;
        }
        .context-user {
          font-size: 1.15rem;
          margin-left: 0.5rem;
          color: var(--muted-strong);
        }
        .bread-divider {
          color: var(--muted-strong);
          min-width: 28px;
          text-align: center;
        }
        .poll-name {
          font-size: 1.15rem;
          color: var(--btn-text-color);
          input {
            font-size: 1.15rem;
            color: var(--btn-text-color);
            border: none;
            padding: 0;
            margin: 0;
            outline: none;
            border-bottom: 1px solid transparent;
            &:hover {
              border-color: var(--muted);
            }
            &:focus {
              border-color: var(--blue);
            }
          }
        }
        .poll-status {
          margin-left: 0.5rem;
          width: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--muted);
          &.unpublished {
            color: var(--muted-strong);
          }
          &.published {
            color: var(--green);
          }
          &.paused {
            color: var(--yellow);
          }

          i {
            display: flex;
          }
        }
      }
      .save-changes-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        background-color: var(--warning-background);
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        grid-gap: 1rem;
        place-content: center;
        font-size: 0.875rem;
        padding: 0.5rem 0.875rem;
        border-radius: var(--border-radius);
        ${'' /* margin: auto 0.5rem; */}
        button {
          ${'' /* margin-left: 10px; */}
          font-size: 0.875rem;
          width: 100px;
          padding: 0.25rem 0.875rem;
          border: var(--border);
          background-color: var(--blue);
          outline: none;
          border-radius: var(--border-radius);
          color: var(--white);
          display: grid;
          grid-template-columns: 14px 1fr;
          align-items: center;
          &:hover {
            background-color: var(--blue-hover);
          }
          i {
            display: flex !important;
            justify-content: center;
            align-items: center;
          }
          .updating {
            display: flex !important;
            justify-content: center;
            align-items: center;
            animation: ${rotate} 2s linear infinite;
            ${'' /* padding: 2rem 1rem;
            font-size: 1.2rem; */}
          }
        }
      }
    }
    .nav-actions {
      display: grid;
      grid-template-columns: 150px 160px 48px;
      grid-gap: 1rem;
      text-align: right;
      align-items: inherit;
      place-content: end;
      .preview-action {
        button {
          font-size: 0.875rem;
          ${'' /* width: 100px; */}
          width: 100%;
          margin-right: 0;
          margin-left: auto;
          padding: 0.5rem 0.5rem;
          border: var(--border);
          background-color: var(--btn-background-color);
          outline: none;
          border-radius: var(--border-radius);
          color: var(--btn-text-color);
          display: grid;
          grid-template-columns: 20px 1fr;
          &:hover {
            background-color: var(--btn-background-color-hover);
          }
        }
      }
      .publish-btn {
        button {
          font-size: 0.875rem;
          ${'' /* width: 100px; */}
          width: 100%;
          margin-right: 0;
          margin-left: auto;
          padding: 0.5rem 0.5rem;
          border: var(--border);
          background-color: var(--blue);
          outline: none;
          border-radius: var(--border-radius);
          color: var(--white);
          display: grid;
          grid-template-columns: 20px 1fr;
          &:hover {
            background-color: var(--blue-hover);
          }
        }
      }
      .publish-action {
        .publish-options {
          width: 160px;
          display: grid;
          grid-template-columns: 1fr 32px;
          grid-gap: 1px;
          margin-right: 0;
          margin-left: auto;
          button {
            width: 100%;
            font-size: 0.875rem;
            padding: 0.5rem 0.5rem;
            ${'' /* border: var(--border); */}
            border: 1px solid var(--blue);
            background-color: var(--blue);
            outline: none;
            color: var(--white);
            &:first-child {
              border-top-left-radius: var(--border-radius);
              border-bottom-left-radius: var(--border-radius);
              border-right: none;
            }
            &:last-child {
              border-top-right-radius: var(--border-radius);
              border-bottom-right-radius: var(--border-radius);
              padding: 0 0.25rem;
              border-right: none;
            }
          }
          .dropdown-arrow {
            &:hover {
              background-color: var(--blue-hover);
              border-color: var(--blue-hover);
            }
          }
          .status {
            cursor: default;
          }
          .publish-action {
            display: grid;
            grid-template-columns: 20px 1fr;
            align-items: center;
          }
        }
      }
      .account-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          padding: 0;
          border-radius: 50%;
          border: 2px solid var(--muted);
          outline: none;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            border-color: var(--blue);
          }
          .user-pic {
            border-radius: 50%;
            font-size: 0.5rem;
          }
        }
        .profile-dropdown {
          margin: -5px -9px;
          display: grid;
          grid-template-columns: 1fr;
          width: 160px;
          .session-details {
            text-align: initial;
            padding: 1rem;
            max-width: 160px;
            overflow: auto;
            .session-user {
              font-size: 1.15rem;
            }
            .session-email {
              color: var(--muted-strong);
              font-size: 0.8rem;
            }
          }

          button {
            border-radius: 0;
            width: 100%;
            height: 100%;
            font-size: 0.875rem;
            padding: 0.8rem 0.5rem;
            border: var(--border);
            ${'' /* background-color: var(--btn-background-color); */}
            background-color: var(--white);
            outline: none;
            border: 0 !important;
            color: var(--btn-text-color);
            display: grid;
            grid-template-columns: 20px 1fr;
            ${
              '' /* &:first-child,
            &:last-child {
              border-top-right-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
              border-top-left-radius: 0 !important;
              border-bottom-left-radius: 0 !important;
            } */
            }
            &:hover {
              background-color: var(--white-hover);
            }
            &.btn-primary {
              background-color: var(--blue);
              color: var(--white);
              &:hover {
                background-color: var(--blue-hover);
              }
            }
            &.btn-logout {
              border-top: var(--border) !important;
            }
          }
        }
      }
    }
  }
  .nav-row {
    height: 35px;
    padding: 0.25rem 1.5rem 0;
    display: grid;
    grid-template-columns: repeat(${({ options }) => options || 3}, 1fr);
    grid-gap: 1.5rem;
    justify-content: center;
    text-align: center;
    align-items: inherit;
    width: 50%;
    margin: auto;
    .tab-item {
      position: relative;
      button {
        width: 100%;
        height: 100%;
        background-color: var(--white);
        border: none;
        outline: none;
        border-radius: 4px;
        color: var(--muted-strong);
        padding: 0 0.875rem 0.5rem;
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          transition: height 0.2s ease;
          opacity: 0;
          background-color: var(--border-color);
        }
        &:hover {
          color: var(--btn-text-color);
          &:after {
            height: 3px;
            background-color: var(--muted-strong);
            opacity: 1;
          }
        }
        &[data-active='true'] {
          color: var(--blue);
          &:after {
            opacity: 1;
            height: 3px;
            background-color: var(--blue);
          }
        }
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.xl}) {
    .info-row {
      grid-template-columns: 60% 1fr;
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.lg}) {
    .info-row {
      grid-template-columns: 50% 1fr;
    }
    .nav-row {
      width: 75%;
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    .info-row {
      grid-template-columns: 1fr;
      padding: 1rem 1rem;
      grid-gap: 1rem;
      .context-user {
        display: none;
      }
      .nav-actions {
        grid-template-columns: 1fr 1fr 40px;
        grid-gap: 0.5rem;
      }
    }
    .nav-row {
      width: 100%;
      grid-gap: 0.5rem;
    }
  }
`;

export const StyledTabNav = styled.div`
  background: white;
  padding: 1rem 0;
  .nav-row {
    padding: 0.25rem 1.5rem 0;
    display: grid;
    grid-template-columns: repeat(${({ options }) => options || 3}, 1fr);
    grid-gap: 1.5rem;
    justify-content: center;
    text-align: center;
    align-items: inherit;
    width: 50%;
    margin: auto;
    .tab-item {
      position: relative;
      button {
        width: 100%;
        height: 100%;
        background-color: var(--white);
        border: none;
        outline: none;
        border-radius: 4px;
        color: var(--muted-strong);
        padding: 0 0.875rem 0.5rem;
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          transition: height 0.2s ease;
          opacity: 0;
          background-color: var(--border-color);
        }
        &:hover {
          color: var(--btn-text-color);
          &:after {
            height: 3px;
            background-color: var(--muted-strong);
            opacity: 1;
          }
        }
        &[data-active='true'] {
          color: var(--blue);
          &:after {
            opacity: 1;
            height: 3px;
            background-color: var(--blue);
          }
        }
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.lg}) {
    .nav-row {
      width: 75%;
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    .nav-row {
      width: 100%;
      grid-gap: 0.5rem;
    }
  }
`;

export const StyledNavbar = styled.div`
  background: var(--white);
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid var(--border-color);
  min-height: 45px;
  padding: 1.5rem 2.5rem;
  display: grid;
  grid-template-columns: 200px auto;
  grid-gap: 0.5rem;
  align-items: center;
  .nav-start {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    .nav-logo {
      width: 130px;
      position: relative;
      height: 30px;
      img {
        object-fit: contain;
      }
    }
  }
  .nav-items {
    display: grid;
    grid-template-columns: auto 225px 150px;
    text-align: right;
    grid-gap: 0.25rem;
    align-items: center;
    .nav-links {
      display: grid;
      ${'' /* grid-template-columns: repeat(5, 1fr); */}
      grid-template-columns: repeat(4, 80px);
      align-items: center;
      grid-gap: 1.5rem;
      padding: 0 0.5rem;
      place-content: flex-start;
      .nav-item {
        text-align: center;
        width: 100%;
        transition: 0.2s all;
        &:hover {
          color: var(--blue);
        }
      }
    }
    .nav-links-wrap {
      display: none;
    }
    .nav-sign {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      grid-gap: 0.25rem;
      padding: 0 0.5rem;
      button {
        width: 100%;
        border: none;
        border-radius: var(--border-radius);
        padding: 0.65rem 0.5rem;
        padding-left: 1rem;
        color: var(--btn-text-color);
        background-color: var(--btn-background-color);
        display: grid;
        grid-template-columns: 14px 1fr;
        grid-gap: 0.15rem;
        align-items: center;
        i {
          display: flex !important;
        }
        &:hover {
          background-color: var(--btn-background-color-hover);
        }
        &.btn-text {
          ${'' /* color: var(--white); */}
          background-color: transparent;
          border: var(--border);
          &:hover {
            background-color: var(--btn-background-color);
          }
        }
        &.btn-primary {
          color: var(--white);
          background-color: var(--blue);
          &:hover {
            background-color: var(--blue-hover);
          }
        }
      }
    }
    .account-btn {
      display: grid;
      justify-content: right;
      padding-right: 0.5rem;
      @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
        justify-content: center;
        ${'' /* margin: auto; */}
        padding: 0;
      }
      button {
        padding: 0;
        border-radius: 50%;
        border: 2px solid var(--muted);
        outline: none;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          border-color: var(--blue);
        }
        .user-pic {
          border-radius: 50%;
          font-size: 0.5rem;
        }
      }
      .profile-dropdown {
        margin: -5px -9px;
        display: grid;
        grid-template-columns: 1fr;
        width: 160px;
        .session-details {
          text-align: initial;
          padding: 1rem;
          max-width: 160px;
          overflow: auto;
          .session-user {
            font-size: 1.15rem;
          }
          .session-email {
            color: var(--muted-strong);
            font-size: 0.8rem;
          }
        }

        button {
          border-radius: 0;
          width: 100%;
          height: 100%;
          font-size: 0.875rem;
          padding: 0.8rem 0.5rem;
          border: var(--border);
          ${'' /* background-color: var(--btn-background-color); */}
          background-color: var(--white);
          outline: none;
          border: 0 !important;
          color: var(--btn-text-color);
          display: grid;
          grid-template-columns: 20px 1fr;
          ${
            '' /* &:first-child,
            &:last-child {
              border-top-right-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
              border-top-left-radius: 0 !important;
              border-bottom-left-radius: 0 !important;
            } */
          }
          &:hover {
            background-color: var(--white-hover);
          }
          &.btn-primary {
            background-color: var(--blue);
            color: var(--white);
            &:hover {
              background-color: var(--blue-hover);
            }
          }
          &.btn-logout {
            border-top: var(--border) !important;
          }
        }
      }
    }
    .nav-action {
      button {
        width: 100%;
        border: none;
        border-radius: var(--border-radius);
        padding: 0.65rem 0.5rem;
        padding-left: 1rem;
        color: var(--btn-text-color);
        background-color: var(--btn-background-color);
        display: grid;
        grid-template-columns: 20px 1fr;
        grid-gap: 0.2rem;
        align-items: center;
        i {
          display: flex !important;
        }
        &:hover {
          background-color: var(--btn-background-color-hover);
        }
        &.btn-orange {
          color: var(--white);
          background-color: var(--orange);
          &:hover {
            background-color: var(--orange-hover);
          }
        }
        &.btn-primary {
          color: var(--white);
          background-color: var(--blue);
          &:hover {
            background-color: var(--blue-hover);
          }
        }
      }
      .create-btn-wrap {
        display: none;
      }
    }
    .nav-wrap {
      display: none;
      button {
        width: 100%;
        border: none;
        border-radius: var(--border-radius);
        padding: 0.5rem 0.5rem;
        ${'' /* padding-left: 1rem; */}
        color: var(--btn-text-color);
        background-color: var(--btn-background-color);
        ${'' /* display: grid;
        grid-template-columns: 1fr;
        grid-gap: 0.15rem; */}
        align-items: center;
        i {
          display: flex !important;
          justify-content: center;
        }
        &:hover {
          background-color: var(--btn-background-color-hover);
        }
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.xl}) {
    grid-template-columns: 150px auto;
    .nav-items {
      grid-template-columns: auto 100px 115px;
      .nav-links {
        padding: 0;
      }
      .nav-sign {
        grid-template-columns: 1fr;
        padding-right: 0;
        .nav-signup-btn {
          display: none;
        }
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.lg}) {
    grid-template-columns: 150px auto;
    .nav-items {
      ${'' /* grid-template-columns: auto 100px 115px; */}
      grid-template-columns: 100px 100px 48px;
      place-content: end;
      grid-gap: 0.5rem;
      .nav-links {
        display: none;
      }
      .nav-sign {
        padding: 0;
      }
      .nav-action {
        display: initial;
        .create-btn {
          display: none;
        }
        .create-btn-wrap {
          display: initial;
        }
      }
      .nav-wrap {
        display: initial;
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    ${'' /* grid-template-columns: 1fr; */}
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    grid-template-columns: 1fr;
    .nav-start {
      place-content: center;
      padding-bottom: 1rem;
    }
    .nav-items {
      grid-template-columns: 1fr 1fr 48px;
    }
  }
`;

export const StyledMobileNav = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;
  padding: 1.5rem;
  padding-bottom: 0.5rem;
  .nav-links {
    width: 100%;
    display: flex;
    justify-content: space-around;
    .nav-item {
      text-align: center;
      width: 100%;
      transition: 0.2s all;
      &:hover {
        color: var(--blue);
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    padding: 1rem;
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    .nav-links {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0.5rem;
      text-align: center;
    }
  }
`;

export const StyledUserNavbar = styled.div`
  ${'' /* height: 80px; */}
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid var(--border-color);
  .nav-logo {
    font-family: var(--font-heading);
    font-weight: 600;
    font-size: 1.25rem;
    letter-spacing: 1px;
    width: 130px;
    position: relative;
    height: 30px;
    img {
      object-fit: contain;
    }
  }
  .info-row {
    ${'' /* min-height: 45px; */}
    min-height: 60px;
    padding: 0.5rem 2.5rem;
    display: grid;
    grid-template-columns: 50% auto;
    grid-gap: 0.5rem;
    align-items: center;
    .nav-context {
      .nav-details-container {
        display: grid;
        grid-template-columns: 1fr;
        .poll-details {
          ${'' /* display: grid;
          grid-template-columns: 1fr 28px 2fr 28px; */}
          display: flex;
          overflow: hidden;
          ${'' /* margin-left: 0.5rem; */}
          align-items: center;
          flex-grow: 1;
        }
        .list-icon {
          color: var(--muted-strong);
          text-align: center;
        }
        .context-user {
          font-size: 1.15rem;
          margin-left: 0.5rem;
          color: var(--muted-strong);
        }
      }
    }
    .nav-actions {
      display: grid;
      grid-template-columns: auto 48px;
      text-align: right;
      align-items: inherit;
      .feedback-nav {
        font-size: 0.875rem;
        width: 100%;
        ${'' /* width: 100px; */}
        ${'' /* margin-right: 0; */}
        ${'' /* margin-left: auto; */}
        padding: 0.5rem 0.25rem;
        border: var(--border);
        background-color: var(--btn-background-color);
        outline: none;
        border-radius: var(--border-radius);
        color: var(--btn-text-color);
        &:hover {
          background-color: var(--btn-background-color-hover);
        }
      }
      .upgrade-nav {
        font-size: 0.875rem;
        width: 100%;
        padding: 0.5rem 0.25rem;
        border: var(--border);
        background-color: var(--blue);
        outline: none;
        border-radius: var(--border-radius);
        color: var(--white);
        &:hover {
          background-color: var(--blue-hover);
        }
      }
      .new-poll-nav {
        font-size: 0.875rem;
        width: 100%;
        padding: 0.5rem 0.25rem;
        border: var(--border);
        background-color: var(--blue);
        outline: none;
        border-radius: var(--border-radius);
        color: var(--white);
        display: flex;
        justify-content: center;
        &:hover {
          background-color: var(--blue-hover);
        }
        i {
          margin-right: 2px;
          display: flex !important;
          justify-content: center;
          align-items: center;
        }
      }
      .nav-right {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 0.5rem;
        align-items: center;
        text-align: center;
        .nav-link {
          ${'' /* font-weight: 500;
          color: var(--muted-strong); */}
          text-align: center;
          width: 100%;
          transition: 0.2s all;
          &:hover {
            color: var(--blue);
            ${'' /* text-decoration: underline; */}
          }
        }
      }
      .feedback-tooltip {
        ${'' /* padding-top: 0.25rem; */}
        & > div {
          width: 250px;
        }
        .tippy-content {
          padding: 0;
        }
        .feedback-block {
          ${'' /* height: 200px; */}
          display: grid;
          grid-template-rows: auto auto;
          ${'' /* grid-gap: 0.5rem; */}
          align-items: center;
          .field-wrapper {
            height: 100%;
            height: 100px;
            textarea {
              width: 100%;
              height: 100%;
              display: block;
              margin: 0 auto;
              border-radius: var(--border-radius);
              border: none;
              padding: 0.75rem;
              font-size: 0.875rem;
              color: var(--text-color);
              resize: none;
              outline: none;
            }
          }
          .feedback-block-footer {
            background-color: var(--white-hover);
            border-top: var(--border);
            ${'' /* height: 5rem; */}
            padding: 0.5rem;
            .footer-actions {
              display: grid;
              grid-template-columns: auto auto;
              placen-content: center;
              grid-gap: 1rem;
              align-items: center;
              padding-bottom: 0.25rem;
              button {
                width: 100%;
                font-size: 0.875rem;
                padding: 0.25rem 1rem;
                border: var(--border);
                border-radius: var(--border-radius);
                outline: none;
                &.btn-primary {
                  border: 1px solid transparent;
                }
              }
            }
            .footer-info {
              padding: 0.25rem 0;
              text-align: initial;
              a {
                color: var(--blue);
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            .action-message {
              padding: 0.25rem;
              border-radius: var(--border-radius);
              border: 1px solid transparent;
              &.success {
                color: var(--green);
                border-color: var(--green);
                background-color: ${({ theme }) => tint(0.9, theme.colors.green)};
              }
              &.error {
                color: var(--red);
                border-color: var(--red);
                background-color: ${({ theme }) => tint(0.9, theme.colors.red)};
              }
            }
          }
        }
      }
      .account-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          ${'' /* width: 35px;
          height: 35px; */}
          padding: 0;
          border-radius: 50%;
          border: 2px solid var(--muted);
          outline: none;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            border-color: var(--blue);
          }
          .user-pic {
            ${
              '' /* position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%; */
            }
            border-radius: 50%;
            font-size: 0.5rem;
          }
        }
        .profile-dropdown {
          margin: -5px -9px;
          display: grid;
          grid-template-columns: 1fr;
          width: 160px;
          .session-details {
            text-align: initial;
            padding: 1rem;
            max-width: 160px;
            overflow: auto;
            .session-user {
              font-size: 1.15rem;
            }
            .session-email {
              color: var(--muted-strong);
              font-size: 0.8rem;
            }
          }

          button {
            border-radius: 0;
            width: 100%;
            height: 100%;
            font-size: 0.875rem;
            padding: 0.8rem 0.5rem;
            border: var(--border);
            ${'' /* background-color: var(--btn-background-color); */}
            background-color: var(--white);
            outline: none;
            border: 0 !important;
            color: var(--btn-text-color);
            display: grid;
            grid-template-columns: 20px 1fr;
            ${
              '' /* &:first-child,
            &:last-child {
              border-top-right-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
              border-top-left-radius: 0 !important;
              border-bottom-left-radius: 0 !important;
            } */
            }
            &:hover {
              background-color: var(--white-hover);
            }
            &.btn-primary {
              background-color: var(--blue);
              color: var(--white);
              &:hover {
                background-color: var(--blue-hover);
              }
            }
            &.btn-logout {
              border-top: var(--border) !important;
            }
          }
        }
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.xl}) {
    .info-row {
      grid-template-columns: 50% 1fr;
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.lg}) {
    grid-template-columns: 30% 1fr;
    .info-row {
      grid-template-columns: 30% 1fr;
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    .info-row {
      .nav-actions {
        .nav-right {
          grid-template-columns: repeat(3, 1fr);
        }
        .feedback-nav {
          display: none;
        }
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    .info-row {
      grid-template-columns: 1fr;
      .poll-details {
        justify-content: center;
        padding-bottom: 0.5rem;
      }
      .nav-actions {
        .feedback-nav {
          display: none;
        }
      }
    }
  }
`;

export const BuilderPageWrapper = styled.div`
  min-height: 100vh;
  ${'' /* background-color: var(--background-alt); */}
  background-color: var(--blue-background-extra-light);
`;

export const LivePageWrapper = styled.div`
  min-height: 100vh;
  padding: 2rem;
  background-color: var(--blue-background-extra-light);
  height: 100%;
  .live-wrapper {
    padding: 4rem 2rem;
    display: grid;
    grid-template-columns: 50% auto;
    grid-gap: 1rem;
    align-items: center;
  }

  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.lg}) {
    .live-wrapper {
      grid-template-columns: 1fr;
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    .live-wrapper {
      padding: 3rem 1rem;
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    .live-wrapper {
      padding: 2rem 0;
    }
  }
  .card-header {
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    padding-left: var(--desktop-padding-sizes);
    padding-right: var(--desktop-padding-sizes);
    .title {
      margin: 0;
    }
    .description {
      margin: 0;
    }
    @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
      padding-top: 2rem;
      padding-bottom: 0.5rem;
      padding-left: var(--mobile-padding-sizes);
      padding-right: var(--mobile-padding-sizes);
    }
  }

  .question-details {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50px 350px;
    align-items: center;
    grid-gap: 1rem;
    .question-nav {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 1rem;
      place-content: center;
      button {
        font-size: 0.875rem;
        width: 150px;
        padding: 0.5rem 0.875rem;
        border: var(--border);
        background-color: var(--blue);
        outline: none;
        border-radius: var(--border-radius);
        color: var(--white);
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          display: flex !important;
        }
        &:hover {
          background-color: var(--blue-hover);
        }
        &:disabled {
          background-color: var(--muted-strong);
        }
      }
    }
  }
  .poll-qr-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const DashboardPageWrapper = styled.div`
  min-height: 100vh;
  ${'' /* background-color: var(--background-alt); */}

  .inner-dashboard {
    min-height: calc(100vh - 60px);
    display: grid;
    grid-template-columns: 25% auto;
    height: 100%;
    .update-folder-tooltip {
      margin: -5px -9px;
      width: 250px;
      padding: 1rem 1.5rem;
      .tooltip-label {
        color: var(--muted-strong);
        font-size: 1rem;
        font-weight: 500;
        padding-bottom: 0.5rem;
      }
      .tooltip-input-wrappper {
        width: 100%;
        padding-bottom: 0.5rem;
        input {
          width: 100%;
          height: 100%;
          ${'' /* display: block; */}
          margin: 0 auto;
          border-radius: var(--border-radius);
          border: var(--border);
          padding: 0.5rem 0.75rem;
          font-size: 1rem;
          color: var(--text-color);
        }
      }
      .tooltip-footer {
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          font-size: 0.875rem;
          padding: 0.5rem 1rem;
          border: 1px solid var(--blue);
          background-color: var(--blue);
          outline: none;
          color: var(--white);
          border-radius: var(--border-radius);
          &:hover {
            background-color: var(--blue-hover);
            border-color: var(--blue-hover);
          }
        }
      }
    }
    .remove-folder-tooltip {
      margin: -5px -9px;
      width: 250px;
      padding: 1rem 1.5rem;
      .tooltip-label {
        color: var(--muted-strong);
        font-size: 1rem;
        font-weight: 500;
        padding-bottom: 0.5rem;
      }
      .tooltip-footer {
        padding-top: 0.25rem;
        display: grid;
        ${'' /* grid-template-columns: 2fr 1fr; */}
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.8rem;
          padding: 0.5rem 1rem;
          outline: none;
          border-radius: var(--border-radius);
          border: 1px solid ${({ theme }) => tint(0.5, theme.colors.red)};
          color: var(--red);
          background-color: ${({ theme }) => tint(0.9, theme.colors.red)};
          &:hover {
            background-color: var(--red);
            color: var(--white);
          }
          i {
            display: flex !important;
            margin-left: 5px;
          }
        }
      }
    }
    .side-nav {
      border-right: var(--border);
      padding: 1.5rem 0;
      padding-bottom: 0;
      position: relative;
      z-index: 1;
      ${'' /* padding-left: 2rem; */}
      .folder-nav {
        position: sticky;
        top: 81px;
        .menu-header {
          padding-bottom: 1rem;
          padding-right: 1rem;
          padding-left: 2rem;
          border-bottom: var(--border);
          button {
            border: none;
            outline: none;
            border-radius: var(--border-radius);
            padding: 0.875rem 1.25rem 0.875rem 1rem;
            color: var(--blue);
            background-color: var(--white);
            display: grid;
            grid-template-columns: 14px 1fr;
            grid-gap: 0.25rem;
            align-items: center;
            i {
              display: flex !important;
            }
            &:hover {
              color: var(--blue-hover);
            }
          }
          .new-folder-tooltip {
            margin: -5px -9px;
            width: 250px;
            padding: 1rem 1.5rem;
            .tooltip-label {
              color: var(--muted-strong);
              font-size: 1rem;
              font-weight: 500;
              padding-bottom: 0.5rem;
            }
            .tooltip-input-wrappper {
              width: 100%;
              padding-bottom: 0.5rem;
              input {
                width: 100%;
                height: 100%;
                ${'' /* display: block; */}
                margin: 0 auto;
                border-radius: var(--border-radius);
                border: var(--border);
                padding: 0.5rem 0.75rem;
                font-size: 1rem;
                color: var(--text-color);
              }
            }
            .tooltip-footer {
              button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                font-size: 0.875rem;
                padding: 0.5rem 1rem;
                border: 1px solid var(--blue);
                background-color: var(--blue);
                outline: none;
                color: var(--white);
                border-radius: var(--border-radius);
                &:hover {
                  background-color: var(--blue-hover);
                  border-color: var(--blue-hover);
                }
              }
            }
          }
        }
        .menu-list {
          padding: 1.5rem 0;
          border-bottom: var(--border);
          .folder-item {
            display: grid;
            grid-template-columns: 70% auto;
            align-items: center;
            background-color: var(--white);
            padding: 1rem 0.875rem;
            ${'' /* padding-right: 1rem; */}
            padding-left: 2.5rem;
            width: 100%;
            height: 100%;
            text-align: inherit;
            font-weight: 400;
            border: none;
            outline: none;
            color: var(--text-color);
            &:hover {
              color: var(--blue);
              background-color: var(--blue-background-extra-light);
            }
            &[data-selected='true'] {
              color: var(--blue);
              background-color: var(--blue-background-extra-light);
              font-weight: 500;
            }
            &.loading {
              ${'' /* border: 2px dashed var(--blue-hover); */}
              position: relative;
              background-color: var(--blue-background-extra-light);
              &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background: linear-gradient(90deg, var(--blue) 50%, transparent 50%),
                  linear-gradient(90deg, var(--blue) 50%, transparent 50%),
                  linear-gradient(0deg, var(--blue) 50%, transparent 50%),
                  linear-gradient(0deg, var(--blue) 50%, transparent 50%);
                background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
                background-size: 10px 3px, 10px 3px, 3px 10px, 3px 10px;
                ${'' /* background-position: 0px 0px, 100% 100%, 0px 100%, 100% 0px; */}
                animation: ${borderDance} 15s infinite linear;
              }
            }
          }
        }
      }
    }
    .dash-content {
      padding: 1.5rem 2.5rem;
      .inner-nav {
        display: grid;
        grid-template-columns: auto 30%;
        align-items: center;
        .filters {
          display: grid;
          grid-template-columns: 50px 50px 50px 50px auto;
          grid-gap: 0.25rem;
          button {
            border: var(--border);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.25rem;
            i {
              display: flex !important;
            }
            &.active {
              color: var(--blue);
              border-color: ${({ theme }) => tint(0.5, theme.colors.blue)};
              background-color: ${({ theme }) => tint(0.9, theme.colors.blue)};
              &:hover {
                background-color: ${({ theme }) => tint(0.825, theme.colors.blue)};
              }
            }
          }
        }
        .actions {
          display: flex;
          justify-content: flex-end;
          button {
            border: none;
            border-radius: var(--border-radius);
            padding: 0.875rem 1.25rem 0.875rem 1rem;
            color: var(--white);
            background-color: var(--blue);
            display: grid;
            grid-template-columns: 18px 1fr;
            grid-gap: 0.25rem;
            align-items: center;
            i {
              display: flex !important;
            }
            &:hover {
              background-color: var(--blue-hover);
            }
          }
        }
      }
      .poll-list {
        padding: 1rem 0;
        .list-heading {
          padding-left: 1rem;
          font-size: 0.875rem;
          font-weight: 500;
          padding-bottom: 1rem;
          border-bottom: var(--border);
          font-family: var(--font-heading);
          display: grid;
          grid-template-columns: auto 24px 24px;
          place-content: start;
          grid-gap: 0.5rem;
          align-items: center;
          .folder-remove {
            .remove-btn {
              background-color: var(--white);
              &:hover {
                background-color: var(--white-hover);
              }
            }
          }
        }
        .list-container {
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          ${'' /* grid-gap: 0.5rem; */}
          ${'' /* padding: 1rem 0; */}
          ${'' /* padding-left: 1rem; */}
          .pinned-poll-item {
            padding: 0.875rem 1.5rem;
            display: grid;
            grid-template-columns: 50% auto;
            align-items: center;
            border-bottom: var(--border);
            position: relative;
            &:last-child {
              border-bottom: none;
            }
            &:hover {
              background-color: var(--white-hover);
            }
            .poll-details {
              .poll-folder-tag {
                display: grid;
                grid-template-columns: auto;
                place-content: start;
                margin-top: -0.5rem;
                .tag {
                  font-size: 0.7rem;
                  padding: 0.25rem 0.875rem;
                  background-color: var(--muted);
                  border-radius: var(--border-radius);
                  ${'' /* font-weight: 600; */}
                  outline: none;
                  color: var(--blue);
                  background-color: ${({ theme }) => tint(0.9, theme.colors.blue)};
                  &:hover {
                    background-color: ${({ theme }) => tint(0.825, theme.colors.blue)};
                  }
                }
              }
            }
            .poll-title {
              font-size: 1.25rem;
              font-weight: 400;
              padding-bottom: 0.25rem;
              font-family: var(--font-heading);
            }
            .status-info {
              font-size: 0.875rem;
              color: var(--muted-strong);
            }
            .pinned-stats {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              grid-gap: 0.25rem;
              align-items: center;
              place-content: center;
              .stat-block {
                text-align: center;
                .stat-label {
                  font-weight: 400;
                  font-size: 1.15rem;
                  color: var(--blue);
                  a {
                    font-weight: 400;
                  }
                }
                &.last-response-sm {
                  display: none;
                }
              }
            }
          }
          .poll-item {
            padding: 1.5rem;
            display: grid;
            grid-template-columns: auto 65%;
            align-items: center;
            border-bottom: var(--border);
            position: relative;
            &:last-child {
              border-bottom: none;
            }
            &:hover {
              background-color: var(--white-hover);
            }
            .poll-pinned-icon {
              position: absolute;
              right: 0.875rem;
              top: 0.5rem;
              transform: rotate(25deg);
              color: var(--blue);
            }
            .poll-details {
              .poll-folder-tag {
                display: grid;
                grid-template-columns: auto;
                place-content: start;
                margin-top: -0.5rem;
                .tag {
                  font-size: 0.7rem;
                  padding: 0.25rem 0.875rem;
                  background-color: var(--muted);
                  border-radius: var(--border-radius);
                  ${'' /* font-weight: 600; */}
                  outline: none;
                  color: var(--blue);
                  background-color: ${({ theme }) => tint(0.9, theme.colors.blue)};
                  &:hover {
                    background-color: ${({ theme }) => tint(0.825, theme.colors.blue)};
                  }
                }
              }
            }
            .poll-title {
              font-size: 1.5rem;
              font-weight: 400;
              padding-bottom: 0.5rem;
              font-family: var(--font-heading);
            }
            .status-info {
              font-size: 0.875rem;
              color: var(--muted-strong);
            }
            .poll-stats {
              display: grid;
              grid-template-columns: 2fr 2fr 2fr 3fr 30px;
              place-content: center;
              align-items: center;
              grid-gap: 0.25rem;
              .stat-block {
                text-align: center;
                .stat-label {
                  font-weight: 400;
                  font-size: 1.25rem;
                  color: var(--blue);
                }
                &.last-response-sm {
                  display: none;
                }
              }
            }
            .poll-options {
              button {
                width: 100%;
                padding: 0;
                border: none;
                background-color: transparent;
                outline: none;
                border-radius: 4px;
                color: var(--btn-text-color);
                padding: 0.5rem 0.25rem;
                &:hover {
                  background-color: var(--btn-background-color);
                }
                &.btn-primary {
                  background-color: var(--blue);
                  color: var(--white);
                  border-radius: var(--border-radius);
                  &:hover {
                    background-color: var(--blue-hover);
                  }
                }
              }
              .poll-item-tooltip {
                button {
                  display: grid;
                  grid-template-columns: 100px 18px;
                  grid-gap: 0.25rem;
                  align-items: center;
                  padding: 0.5rem 0.875rem;
                  &.delete-btn {
                    color: var(--danger-hover);
                  }
                  &.pinned-active {
                    color: var(--blue);
                    border-color: ${({ theme }) => tint(0.5, theme.colors.blue)};
                    background-color: ${({ theme }) => tint(0.85, theme.colors.blue)};
                    &:hover {
                      background-color: ${({ theme }) => tint(0.9, theme.colors.blue)};
                    }
                  }
                }
                .poll-remove-tooltip {
                  padding: 0.5rem;
                  width: 180px;
                  .tooltip-label {
                    font-size: 1rem;
                    font-family: var(--font-heading);
                    padding: 0.5rem 0;
                    background-color: var(--muted);
                    border-radius: var(--border-radius);
                  }
                  .tooltip-info-text {
                    padding: 0.5rem 0;
                    text-align: left;
                  }
                  .remove-footer {
                    padding: 0.5rem 0;
                    display: grid;
                    grid-template-columns: 1fr 2fr;
                    grid-gap: 0.25rem;
                    button {
                      display: block;
                      padding: 0.25rem 0.25rem;
                      &.btn-remove {
                        color: var(--danger-hover);
                        ${'' /* background-color: var(--danger-background); */}
                      }
                      &.btn-cancel {
                        border: var(--border);
                      }
                    }
                  }
                }
              }
            }
          }
          .dashboard-empty-wrapper {
            background-color: var(--blue-background-extra-light);
            margin-top: 2.5rem;
            width: 100%;
            min-height: 350px;
            display: grid;
            grid-gap: 0.5rem;
            place-content: center;
            text-align: center;
            border: 2px solid var(--blue-background-light);
            border-style: dashed;
            border-radius: var(--border-radius);
            padding: 2rem;
            color: var(--muted-strong);
            font-size: 1.15rem;
            font-weight: 400;
            font-family: var(--font-heading);
            z-index: 1;
            .empty-img-wrapper {
              width: 100%;
              min-width: 350px;
              height: 350px;
              position: relative;
              img {
                object-fit: contain;
                opacity: 0.7;
                z-index: -1;
              }
              @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
                min-width: 250px;
                height: 250px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    .inner-dashboard {
      grid-template-columns: 1fr;
      .side-nav {
        .folder-nav {
          position: inherit;
          top: 0;
          .menu-list {
            padding: 0;
          }
        }
      }
      .dash-content {
        padding: 1.5rem 0;
        .inner-nav {
          grid-template-columns: 50% auto;
          padding: 0 0.5rem;
          .actions {
            button {
              padding: 0.875rem 0.875rem 0.875rem 0.5rem;
            }
          }
        }
        .poll-list {
          .list-container {
            .poll-item {
              grid-template-columns: 1fr;
              .poll-details {
                text-align: center;
                padding-bottom: 0.875rem;
              }
              .poll-stats {
                grid-template-columns: 2fr 2fr 2fr 30px;
                .stat-block.last-response {
                  display: none;
                }
                .stat-block.last-response-sm {
                  display: initial;
                  grid-column-start: 1;
                  grid-column-end: -1;
                  padding-top: 0.875rem;
                }
              }
            }
            .pinned-poll-item {
              grid-template-columns: 1fr;
              .poll-details {
                text-align: center;
                padding-bottom: 0.875rem;
              }
            }
          }
        }
      }
    }
  }
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: middle;
  position: relative;
  z-index: 0;
  &.full-height {
    min-height: 100vh;
    position: relative;
  }
  .builder-help {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 70px;
    z-index: -1;
    button {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: sticky;
      top: 110px;
      left: 1rem;
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      color: var(--blue);
      border: 3px solid;
      border-color: ${({ theme }) => tint(0.6, theme.colors.blue)};
      background-color: ${({ theme }) => tint(0.8, theme.colors.blue)};
      &:hover {
        background-color: ${({ theme }) => tint(0.85, theme.colors.blue)};
      }
      i {
        display: flex !important;
        margin-left: -2px;
      }
    }
    .builder-help-tooltip {
      width: 150px;
      font-size: 0.8rem;
      padding: 0.5rem 0.15rem;
      a {
        color: var(--blue);
        &:hover {
          text-decoration: underline;
        }
      }
    }
    @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
      display: none;
    }
  }
  .card-header {
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    padding-left: var(--desktop-padding-sizes);
    padding-right: var(--desktop-padding-sizes);
    .title {
      margin: 0;
    }
    .description {
      margin: 0;
    }
    @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
      padding-top: 2rem;
      padding-bottom: 0.5rem;
      padding-left: var(--mobile-padding-sizes);
      padding-right: var(--mobile-padding-sizes);
    }
  }
  .poll-container {
    z-index: 1;
    &.with-background {
      background-color: var(--blue-background-extra-light);
    }
    &.examples {
      width: 80%;
      @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
        width: 100%;
      }
    }
    &.template {
      .card-template {
        ${'' /* width: var(--breakpoint-md); */}
        width: 100%;
        ${
          '' /* @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.xl}) {
          width: var(--breakpoint-sm);
        } */
        }
        ${
          '' /* @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
          width: 100%;
        } */
        }
      }
      .card-header {
        padding-top: 2rem;
        padding-bottom: 0.5rem;
        padding-left: var(--mobile-padding-sizes);
        padding-right: var(--mobile-padding-sizes);
      }
      .card-body {
        .form-header {
          padding: 0.5rem var(--mobile-padding-sizes);
        }
      }
    }
    &.skeleton {
      min-width: var(--breakpoint-xs);
    }
  }
`;

export const CardWrapper = styled.div`
  height: 100%;
  height: fit-content;
  z-index: 2;
  margin: 2rem auto;
  background-color: var(--white);
  width: 100%;
  width: var(--breakpoint-md);
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.lg}) {
    width: var(--breakpoint-sm);
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    width: 100%;
  }
  border-radius: var(--border-radius);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 2px, rgba(0, 0, 0, 0.03) 0px 3px 5px,
    rgba(0, 0, 0, 0.03) 0px 5px 8px, rgba(0, 0, 0, 0.04) 0px 8px 20px,
    rgba(0, 0, 0, 0.03) 0px 10px 20px, rgba(0, 0, 0, 0.03) 0px 10px 30px,
    rgba(0, 0, 0, 0.02) 0px -5px 30px;
  .card-header {
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    padding-left: var(--desktop-padding-sizes);
    padding-right: var(--desktop-padding-sizes);
    .title {
      margin: 0;
    }
    .description {
      margin: 0;
    }
  }
  .card-body {
    padding-top: 1.5rem;
    .form-header {
      position: relative;
      padding: 1rem var(--desktop-padding-sizes) 0.5rem;
      z-index: 1;
      position: sticky;
      background-color: var(--white);
      top: -1px;
      .title {
        margin: 0;
        margin-bottom: 0.25rem;
      }
      .description {
        margin: 0;
      }
      &:before {
        position: absolute;
        content: '';
        height: 60px;
        width: 100%;
        bottom: -35px;
        left: 0;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          rgba(255, 255, 255, 1) 60%,
          rgba(255, 255, 255, 0.8) 70%,
          rgba(255, 255, 255, 0.4) 80%,
          rgba(255, 255, 255, 0.1) 90%,
          rgba(255, 255, 255, 0) 95%,
          rgba(255, 255, 255, 0) 100%
        );
        z-index: -1;
      }
    }
    .form-body {
      ${'' /* padding: 1rem 3rem 3rem 3rem; */}
      padding-top: 1rem;
      padding-bottom: 3rem;
    }
    .form-footer {
      padding: 0;
      .powered-link {
        display: flex;
        justify-content: center;
        z-index: 5;
        .link-wrapper {
          border: 1px solid var(--blue-background);
          display: grid;
          grid-template-columns: 15px auto;
          grid-gap: 0.25rem;
          align-items: center;
          padding: 0.25rem 0.875rem;
          padding-bottom: 0.15rem;
          border-radius: 25px;
          background-color: var(--blue-background-extra-light);
          &:hover {
            background-color: var(--blue-background-light);
            border-color: var(--blue-hover);
          }
        }
      }
    }
  }
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
    /** inner scrolling */
    ${'' /* max-height: 60vh;
    overflow: scroll; */}
  }
  pre {
    padding: 1rem 2rem;
    font-size: 0.8rem;
    max-width: 400px;
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    width: 100%;
    min-width: auto;
    .card-header {
      padding-top: 2rem;
      padding-bottom: 0.5rem;
      padding-left: var(--mobile-padding-sizes);
      padding-right: var(--mobile-padding-sizes);
    }
    .card-body {
      .form-header {
        padding: 0.5rem var(--mobile-padding-sizes);
      }
    }
  }
`;

export const BuilderCardWrapper = styled.div`
  z-index: 2;
  margin: 2rem auto;
  background-color: var(--white);
  width: 100%;
  width: ${({ fluid }) => (fluid ? 'var(--breakpoint-lg)' : 'var(--breakpoint-md)')};
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.lg}) {
    width: ${({ fluid }) => (fluid ? 'var(--breakpoint-md)' : 'var(--breakpoint-sm)')};
  }
  border-radius: var(--border-radius);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 2px, rgba(0, 0, 0, 0.03) 0px 3px 5px,
    rgba(0, 0, 0, 0.03) 0px 5px 8px, rgba(0, 0, 0, 0.04) 0px 8px 20px,
    rgba(0, 0, 0, 0.03) 0px 10px 20px, rgba(0, 0, 0, 0.03) 0px 10px 30px,
    rgba(0, 0, 0, 0.02) 0px -5px 30px;
  &.simple-height {
    height: 100%;
  }
  .card-header {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: var(--desktop-padding-sizes);
    padding-right: var(--desktop-padding-sizes);
    .title {
      margin: 0;
    }
    .description {
      margin: 0;
    }
  }
  a {
    color: var(--blue);
  }
  .card-body {
    padding-top: 1.5rem;
    .form-header {
      position: relative;
      padding: 0.5rem var(--desktop-padding-sizes);
      z-index: 1;
      background-color: var(--white);
      top: -1px;
      display: grid;
      grid-template-columns: 9fr 1fr;
      .title {
        margin: 0;
        margin-bottom: 0.25rem;
      }
      .description {
        margin: 0;
      }
      .header-actions {
        text-align: end;
        margin-right: -1.5rem;
        button {
          border: none;
          background-color: var(--white);
          outline: none;
          border-radius: 4px;
          color: var(--btn-text-color);
          padding: 0.25rem 0.15rem;
          &:hover {
            background-color: var(--btn-background-color);
          }
        }
      }
      .title-input {
        font-size: 1.5em;
        font-weight: 500;
        padding: 0;
        margin: 0;
        margin-bottom: 0.25rem;
        border: none;
        outline: none;
        border-bottom: 1px solid transparent;
        color: var(--text-color);
        width: 100%;
        font-family: var(--font-heading), -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
          Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        &:hover {
          border-color: var(--muted);
        }
        &:focus {
          border-color: var(--blue);
        }
      }
      .description-input {
        color: var(--text-color);
        border: none;
        padding: 0;
        font-size: inherit;
        outline: none;
        border-bottom: 1px solid transparent;
        width: 100%;
        &:hover {
          border-color: var(--muted);
        }
        &:focus {
          border-color: var(--blue);
        }
      }
    }
    .form-body {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .page-options-list {
      padding: 0.5rem;
      text-align: initial;
      .list-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.5rem;
        button {
          background-color: var(--white-hover);
        }
        &.general {
          padding-bottom: 0.5rem;
          grid-template-columns: 1fr;
        }
        .opt-element {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 0.2rem;
          .inner-cols {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 0.2rem;
          }
          button {
            background-color: var(--white-hover);
            &:hover {
              background-color: var(--btn-background-color);
            }
            &:disabled {
              color: var(--btn-disabled-color);
              background-color: var(--white);
              &:hover {
                background-color: inherit;
                transform: none;
                box-shadow: inherit;
              }
            }
          }
        }
      }
      .option-label {
        color: var(--muted-strong);
      }
    }
  }
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  .form-footer {
    padding: 0;
    .powered-link {
      display: flex;
      justify-content: center;
      z-index: 5;
      .link-wrapper {
        border: 1px solid var(--blue-background);
        display: grid;
        grid-template-columns: 15px auto;
        grid-gap: 0.25rem;
        align-items: center;
        padding: 0.25rem 0.875rem;
        padding-bottom: 0.15rem;
        border-radius: 25px;
        background-color: var(--blue-background-extra-light);
        &:hover {
          background-color: var(--blue-background-light);
          border-color: var(--blue-hover);
        }
      }
    }
  }

  .new-page-wrapper {
    text-align: center;
    padding: 2rem var(--desktop-padding-sizes);
    .new-page {
      border: 2px var(--muted-strong);
      border-style: dashed;
      border-radius: 4px;
      button {
        width: 100%;
        height: 100%;
        padding: 2.5rem 2rem;
        background-color: var(--white);
        border: none;
        color: var(--muted-strong);
        &:hover {
          background-color: var(--white-hover);
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
  .new-question-wrapper {
    text-align: center;
    padding: 1rem var(--desktop-padding-sizes);
    padding-bottom: 3rem;
    .new-question {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 0.5rem;
      align-items: center;
      button {
        border: 1px var(--muted-strong);
        border-style: dashed;
        border-radius: 4px;
        width: 100%;
        height: 100%;
        padding: 0.5rem 0;
        background-color: var(--white);
        font-size: 0.875rem;
        color: var(--muted-strong);
        border-style: dashed;
        &.blank-project {
          border: 2px var(--muted-strong);
        }
        &.likert {
          color: var(--red);
          border-color: ${({ theme }) => tint(0.5, theme.colors.red)};
          &:hover {
            background-color: ${({ theme }) => tint(0.95, theme.colors.red)};
          }
        }
        &.nps {
          color: var(--blue);
          border-color: ${({ theme }) => tint(0.5, theme.colors.blue)};
          &:hover {
            background-color: ${({ theme }) => tint(0.95, theme.colors.blue)};
          }
        }
        &.buttons {
          color: var(--orange);
          border-color: ${({ theme }) => tint(0.5, theme.colors.orange)};
          &:hover {
            background-color: ${({ theme }) => tint(0.95, theme.colors.orange)};
          }
        }
        &.rating {
          color: var(--yellow);
          border-color: ${({ theme }) => tint(0.5, theme.colors.yellow)};
          &:hover {
            background-color: ${({ theme }) => tint(0.95, theme.colors.yellow)};
          }
        }
        &.sentiment {
          color: var(--green);
          border-color: ${({ theme }) => tint(0.5, theme.colors.green)};
          &:hover {
            background-color: ${({ theme }) => tint(0.95, theme.colors.green)};
          }
        }
        &:hover {
          background-color: var(--white-hover);
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
  .card-nav {
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0;
    .left-nav {
      display: grid;
      grid-template-columns: 95px 1fr;
      grid-gap: 0.25rem;
      align-items: center;
      .card-logo {
        width: 85px;
        display: grid;
        grid-template-columns: auto;
        align-items: center;
        ${'' /* width: 130px; */}
        position: relative;
        ${'' /* height: 30px; */}
        .nav-logo {
          ${'' /* width: 130px; */}
          position: relative;
          height: 30px;
          img {
            object-fit: contain;
          }
        }
      }

      .lang-select {
        width: 85px;
        .card-lang-dropdown {
          font-size: 0.875rem;
          .card-lang-dropdown__control {
            min-height: auto;
            height: 2rem;
            border: none;
            background-color: var(--btn-background-color);
            .card-lang-dropdown__indicator-separator {
              display: none;
            }
            .card-lang-dropdown__dropdown-indicator {
              padding: 0 4px;
            }
          }

          .card-lang-dropdown__value-container {
            height: 100%;
            .card-lang-dropdown__input {
              input {
                height: inherit;
              }
            }
          }
        }
      }
    }

    .right-nav {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0.5rem;
      button {
        border: none;
        border-radius: var(--border-radius);
        padding: 0.5rem 1.25rem 0.5rem 1rem;
        color: var(--btn-text-color);
        background-color: var(--btn-background-color);
        display: grid;
        grid-template-columns: 14px 1fr;
        grid-gap: 0.25rem;
        align-items: center;
        i {
          display: flex !important;
        }
        &:hover {
          background-color: var(--btn-background-color-hover);
        }
        &.btn-primary {
          color: var(--white);
          background-color: var(--blue);
          &:hover {
            background-color: var(--blue-hover);
          }
        }
      }
      .profile-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          width: auto;
          height: auto;
          border-radius: 50%;
          border: 2px solid var(--muted);
          outline: none;
          position: relative;
          &:hover {
            border-color: var(--blue);
          }
          .user-pic {
            border-radius: 50%;
            font-size: 0.5rem;
          }
        }
        .profile-dropdown {
          margin: -5px -9px;
          display: grid;
          grid-template-columns: 1fr;
          width: 160px;
          .session-details {
            text-align: initial;
            padding: 1rem;
            max-width: 160px;
            overflow: auto;
            .session-user {
              font-size: 1.15rem;
            }
            .session-email {
              color: var(--muted-strong);
              font-size: 0.8rem;
            }
          }

          button {
            border-radius: 0;
            width: 100%;
            height: 100%;
            font-size: 0.875rem;
            padding: 0.8rem 0.5rem;
            border: var(--border);
            ${'' /* background-color: var(--btn-background-color); */}
            background-color: var(--white);
            outline: none;
            border: 0 !important;
            color: var(--btn-text-color);
            display: grid;
            grid-template-columns: 20px 1fr;
            ${
              '' /* &:first-child,
            &:last-child {
              border-top-right-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
              border-top-left-radius: 0 !important;
              border-bottom-left-radius: 0 !important;
            } */
            }
            &:hover {
              background-color: var(--white-hover);
            }
            &.btn-primary {
              background-color: var(--blue);
              color: var(--white);
              &:hover {
                background-color: var(--blue-hover);
              }
            }
            &.btn-logout {
              border-top: var(--border) !important;
            }
          }
        }
      }
      @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
        grid-template-columns: 1fr;
        grid-gap: 0;
        .btn-signup {
          display: none;
        }
      }
    }
  }
  .settings-body,
  .results-body {
    padding: 2rem var(--desktop-padding-sizes);
    padding-bottom: 3rem;
    .inner-header {
      padding-top: 1rem;
      padding-bottom: 0.5rem;
      .title {
        margin: 0;
        margin-bottom: 0.25rem;
      }
      .header {
        margin-top: 0;
        text-align: center;
      }
      &.actions {
        display: grid;
        grid-template-columns: auto 125px;
        align-items: center;
        grid-gap: 0.25rem;
        button {
          width: 100%;
          font-size: 0.875rem;
          box-shadow: 2px 2px 8px 0 rgba(29, 36, 86, 0.03);
          transition: 0.2s all;
          height: var(--item-height);
          align-items: center;
          display: flex;
          justify-content: center;
          height: 2rem;
          padding: 0;
          i {
            display: flex !important;
            margin-left: 5px;
          }
          &:hover {
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
          }
          &:disabled {
            background-color: var(--white-hover);
            box-shadow: inherit;
          }
          .updating {
            display: flex !important;
            justify-content: center;
            align-items: center;
            animation: ${rotate} 2s linear infinite;
          }
          &.single-icon {
            i {
              margin: 0;
            }
          }
        }
        .actions-right {
          display: grid;
          grid-template-columns: 32px 1fr;
          grid-gap: 0.5rem;
          align-items: center;
        }
      }
    }
    .inner-container {
      .loading-wrapper {
        background-color: var(--blue-background-extra-light);
        margin-top: 2rem;
        width: 100%;
        place-content: center;
        text-align: center;
        border: 2px solid var(--blue-background-light);
        border-style: dashed;
        border-radius: var(--border-radius);
        padding: 1rem;
        color: var(--muted-strong);
        font-size: 1.15rem;
        font-weight: 400;
        font-family: var(--font-heading);
      }
      .empty-response-state {
        background-color: var(--blue-background-extra-light);
        margin-top: 2rem;
        width: 100%;
        min-height: 300px;
        display: grid;
        grid-gap: 0.5rem;
        place-content: center;
        text-align: center;
        border: 2px solid var(--blue-background-light);
        border-style: dashed;
        border-radius: var(--border-radius);
        padding: 2rem;
        color: var(--muted-strong);
        font-size: 1.15rem;
        font-weight: 400;
        font-family: var(--font-heading);
        z-index: 1;
        .empty-img-wrapper {
          width: 100%;
          min-width: 300px;
          height: 300px;
          position: relative;
          z-index: 0;
          img {
            object-fit: contain;
            opacity: 0.7;
            z-index: -1;
          }
          @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
            min-width: 250px;
            height: 250px;
          }
        }
      }
      .settings-layout {
        display: grid;
        grid-template-columns: 150px 1fr;
        grid-gap: 0.5rem;
        .left-nav {
          padding: 1rem 0.5rem 0 0;
          display: grid;
          grid-template-rows: auto auto auto;
          grid-template-columns: 1fr;
          place-content: start;
          grid-gap: 0.5rem;
          .tab-item {
            position: relative;
            button {
              width: 100%;
              height: 100%;
              background-color: transparent;
              border: none;
              outline: none;
              border-radius: 4px;
              padding: 0.5rem 0.875rem;
              color: var(--muted-strong);
              &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 1px;
                height: 100%;
                transition: width 0.2s ease;
                opacity: 0;
                background-color: var(--border-color);
              }
              &:hover {
                color: ${({ theme }) => tint(0.1, theme.colors.blue)};
                &:after {
                  width: 3px;
                  background-color: ${({ theme }) => tint(0.5, theme.colors.blue)};
                  opacity: 1;
                }
              }
              &:disabled {
                &:hover {
                  color: var(--muted-strong);
                }
                &:after {
                  display: none;
                }
              }
              &[data-active='true'] {
                color: var(--blue);
                background-color: ${({ theme }) => tint(0.9, theme.colors.blue)};
                &:hover {
                  background-color: ${({ theme }) => tint(0.825, theme.colors.blue)};
                }
                &:after {
                  opacity: 1;
                  width: 3px;
                  background-color: var(--blue);
                }
              }
            }
            .premium-tag-tooltip {
              width: 220px;
              font-size: 0.8rem;
              padding: 0.5rem 0.15rem;
              a {
                color: var(--blue);
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            .premium-tag {
              position: absolute;
              top: -0.5rem;
              left: -0.5rem;
              display: grid;
              grid-template-columns: auto;
              place-content: end;
              button.tag {
                font-size: 0.7rem;
                padding: 0.15rem 0.65rem 0.1rem 0.5rem;
                border-radius: var(--border-radius);
                outline: none;
                color: var(--red);
                background-color: ${({ theme }) => tint(0.9, theme.colors.red)};
                &:hover {
                  background-color: ${({ theme }) => tint(0.825, theme.colors.red)};
                }
                &:after {
                  display: none;
                }
              }
            }
          }
        }
        .settings-content {
          .input-group {
            padding-bottom: 1rem;
            .label {
              font-size: 0.875rem;
              font-weight: 600;
              color: var(--muted-strong);
              padding-bottom: 0.5rem;
              .label-actions {
                button {
                  ${'' /* background-color: transparent; */}
                  font-size: 0.7rem;
                  margin-left: 5px;
                  color: var(--muted-strong);
                  ${
                    '' /* &:hover {
                    text-decoration: underline;
                  } */
                  }
                }
              }
            }
          }
          .webhook-url {
            width: 100%;
            display: grid;
            grid-template-columns: auto 150px;
            grid-gap: 0.5rem;
            align-items: center;
            input {
              display: block;
              width: 100%;
              margin: 0 auto;
              border-radius: var(--border-radius);
              border: var(--border);
              padding: 0.75rem;
              font-size: 1.1rem;
              color: var(--text-color);
              height: 32px;
              &:disabled {
                background-color: var(--white);
              }
            }
            button {
              height: 100%;
              width: 100%;
              border: var(--border);
              border-left: 0;
              background-color: var(--blue);
              color: var(--white);
              outline: none;
              &:hover {
                background-color: var(--blue-hover);
              }
            }
          }
          .url-slug {
            width: 100%;
            display: grid;
            grid-template-columns: auto 1fr 100px;
            grid-gap: 0.5rem;
            align-items: center;
            .base-url {
              font-size: 1.1rem;
              color: var(--muted-strong);
            }
            &.with-hide {
              grid-template-columns: 1fr 32px 64px;
            }
            input {
              display: block;
              width: 100%;
              margin: 0 auto;
              border-radius: var(--border-radius);
              border: var(--border);
              padding: 0.75rem;
              font-size: 1.1rem;
              color: var(--text-color);
              height: 32px;
              &:disabled {
                background-color: var(--white);
              }
              &.blur-text {
                color: transparent;
                text-shadow: 3px 0 5px rgba(0, 0, 0, 0.5);
              }
            }
            button {
              height: 100%;
              width: 100%;
              border: var(--border);
              border-left: 0;
              background-color: var(--blue);
              color: var(--white);
              outline: none;
              &:hover {
                background-color: var(--blue-hover);
              }
              &.action-blur {
                background-color: var(--white);
                color: inherit;
                &:hover {
                  background-color: var(--white-hover);
                }
                border-right: 0;
                border-radius: 0;
              }
            }
          }
          .share-link {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 64px;
            align-items: center;
            &.with-hide {
              grid-template-columns: 1fr 32px 64px;
            }
            input {
              display: block;
              width: 100%;
              margin: 0 auto;
              border-radius: var(--border-radius);
              border: var(--border);
              padding: 0.75rem;
              font-size: 1.1rem;
              color: var(--text-color);
              height: 32px;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              &:disabled {
                background-color: var(--white);
              }
              &.blur-text {
                color: transparent;
                text-shadow: 3px 0 5px rgba(0, 0, 0, 0.5);
              }
            }
            button {
              height: 100%;
              width: 100%;
              border: var(--border);
              border-left: 0;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              background-color: var(--blue);
              color: var(--white);
              outline: none;
              &:hover {
                background-color: var(--blue-hover);
              }
              &.action-blur {
                background-color: var(--white);
                color: inherit;
                &:hover {
                  background-color: var(--white-hover);
                }
                border-right: 0;
                border-radius: 0;
              }
            }
          }
          .share-action {
            width: 100%;
            button {
              font-size: 0.875rem;
              padding: 0.5rem;
              height: 100%;
              width: 100%;
              border: 1px dashed var(--muted-strong);
              background-color: var(--white);
              outline: none;
              &:hover {
                background-color: var(--white-hover);
              }
              &:disabled {
                color: var(--muted-strong);
                background-color: var(--muted);
              }
            }
          }
        }
        @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
          grid-template-columns: 1fr;
          .left-nav {
            padding: 0.5rem 0;
            grid-template-rows: auto;
            grid-template-columns: 1fr 1fr 1fr;
            place-content: center;
            grid-gap: 0.25rem;
            .tab-item {
              button {
                &:after {
                  width: 100%;
                  height: 1px;
                }
                &:hover {
                  &:after {
                    height: 3px;
                    width: 100%;
                  }
                }
                &[data-active='true'] {
                  &:after {
                    height: 3px;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .share-block {
      border: 1px dashed var(--blue-background);
      padding: 1rem;
      margin-bottom: 1rem;
      background-color: var(--blue-background-extra-light);
      border-radius: var(--border-radius);
      text-align: center;
      position: relative;
      .close-btn {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.2rem 0.25rem 0.15rem 0.2rem;
          margin: 0;
          border: 0;
          outline: 0;
          i {
            display: flex !important;
            padding: 0;
            margin: 0;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .heading-message {
        text-align: center;
        font-family: var(--font-heading);
        font-size: 1.5rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      .share-tabs {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
        width: 50%;
        margin: auto;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        padding-bottom: 1.5rem;
        .tab-item {
          position: relative;
          button {
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: none;
            outline: none;
            border-radius: 4px;
            color: var(--muted-strong);
            padding: 0 0.875rem 0.5rem;
            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              transition: height 0.2s ease;
              opacity: 0;
              background-color: var(--border-color);
            }
            &:hover {
              color: var(--btn-text-color);
              &:after {
                height: 3px;
                background-color: var(--muted-strong);
                opacity: 1;
              }
            }
            &:disabled {
              &:hover {
                color: var(--muted-strong);
              }
              &:after {
                display: none;
              }
            }
            &[data-active='true'] {
              color: var(--blue);
              &:after {
                opacity: 1;
                height: 3px;
                background-color: var(--blue);
              }
            }
          }
          .premium-tag-tooltip {
            width: 220px;
            font-size: 0.8rem;
            padding: 0.5rem 0.15rem;
            a {
              color: var(--blue);
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .premium-tag {
            position: absolute;
            top: -1.25rem;
            right: -2rem;
            display: grid;
            grid-template-columns: auto;
            place-content: end;
            button.tag {
              font-size: 0.7rem;
              padding: 0.15rem 0.65rem 0.1rem 0.5rem;
              border-radius: var(--border-radius);
              outline: none;
              color: var(--red);
              background-color: ${({ theme }) => tint(0.9, theme.colors.red)};
              &:hover {
                background-color: ${({ theme }) => tint(0.825, theme.colors.red)};
              }
              &:after {
                display: none;
              }
            }
          }
        }
      }
      .share-content {
        .content-helper {
          font-size: 0.875rem;
        }
        .share-link {
          width: 100%;
          padding-top: 1rem;
          padding-bottom: 1rem;
          display: grid;
          grid-template-columns: 24px 1fr 64px;
          align-items: center;
          input {
            display: block;
            width: 100%;
            margin: 0 auto;
            border-radius: var(--border-radius);
            border: var(--border);
            padding: 0.75rem;
            font-size: 1.1rem;
            color: var(--text-color);
            height: 32px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            &:disabled {
              background-color: var(--white);
            }
          }
          button {
            height: 100%;
            width: 100%;
            border: var(--border);
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            background-color: var(--blue);
            color: var(--white);
            outline: none;
            &:hover {
              background-color: var(--blue-hover);
            }
          }
        }
        .share-embed {
          width: 100%;
          padding-top: 1rem;
          ${'' /* padding-bottom: 1rem; */}
          textarea {
            display: block;
            width: 100%;
            margin: 0 auto;
            border-radius: var(--border-radius);
            border: var(--border);
            padding: 0.5rem;
            font-size: 0.85rem;
            color: var(--text-color);
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            height: 4.5rem;
            &:disabled {
              background-color: var(--white);
            }
          }
        }
        .share-button {
          padding-top: 1rem;
          padding-bottom: 1rem;
          button {
            height: 32px;
            width: 100%;
            border: var(--border);
            border-left: 0;
            border-radius: var(--border-radius);
            background-color: var(--blue);
            color: var(--white);
            outline: none;
            &:hover {
              background-color: var(--blue-hover);
            }
          }
        }
      }
    }
    .settings-group {
      padding-top: 0.5rem;
      padding-bottom: 1rem;

      .poll-remove-tooltip {
        padding: 0.5rem;
        width: 180px;
        button {
          width: 100%;
          padding: 0;
          border: none;
          background-color: transparent;
          outline: none;
          border-radius: 4px;
          color: var(--btn-text-color);
          padding: 0.5rem 0.25rem;
          &:hover {
            background-color: var(--btn-background-color);
          }
        }
        .tooltip-label {
          font-size: 1rem;
          font-family: var(--font-heading);
          padding: 0.5rem 0;
          background-color: var(--muted);
          border-radius: var(--border-radius);
          text-align: center;
        }
        .tooltip-info-text {
          padding: 0.5rem 0;
          text-align: left;
        }
        .remove-footer {
          padding: 0.5rem 0;
          display: grid;
          grid-template-columns: 1fr 2fr;
          grid-gap: 0.25rem;
          button {
            display: block;
            padding: 0.25rem 0.25rem;
            border: none;
            &.btn-remove {
              color: var(--danger-hover);
              ${'' /* background-color: var(--danger-background); */}
            }
            &.btn-cancel {
              border: var(--border);
            }
          }
        }
      }
    }
    .group-title {
      font-weight: 500;
      font-size: 1.15rem;
      font-family: var(--font-heading);
      padding-bottom: 0.25rem;
      position: relative;
      .group-title-actions {
        display: inline-flex;
        transform: translateY(-2px);
        .premium-tag-tooltip {
          width: 220px;
          font-size: 0.8rem;
          padding: 0.5rem 0.15rem;
          a {
            color: var(--blue);
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .premium-tag {
          ${'' /* position: absolute;
          top: -0.5rem;
          left: -0.5rem; */}
          display: grid;
          grid-template-columns: auto;
          place-content: end;
          button.tag {
            ${'' /* font-size: 0.7rem; */}
            ${'' /* padding: 0.15rem 0.65rem 0.1rem 0.5rem; */}
            border-radius: var(--border-radius);
            outline: none;
            color: var(--red);
            background-color: ${({ theme }) => tint(0.9, theme.colors.red)};
            &:hover {
              background-color: ${({ theme }) => tint(0.825, theme.colors.red)};
            }
            &:after {
              display: none;
            }
          }
        }
      }
    }
    .view-more-collapsible {
      padding: 0.5rem 0;
      button {
        width: 50%;
        display: grid;
        grid-template-columns: 32px auto;
        grid-gap: 0.25rem;
        place-content: start;
        align-items: center;
        padding: 0.5rem 0.25rem;
        background-color: var(--white);
        &:hover {
          background-color: var(--white-hover);
        }
        &:disabled {
          color: var(--muted-strong);
          background-color: var(--muted);
        }
        @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
          width: 100%;
        }
      }
    }
    .group-options {
      padding: 0.5rem 0;
      .input-question.publish-details {
        padding: 0.5rem 0;
      }
    }
    .option-details {
      padding-bottom: 0.5rem;
      color: var(--muted-strong);
    }
    .prev-polls-container {
      margin-top: 1rem;
      padding: 1rem;
      border: var(--border);
      border-style: dashed;
      border-radius: var(--border-radius);
      background-color: var(--white-hover);
      .section-title {
        font-weight: 400;
        font-size: 1rem;
        font-family: var(--font-heading);
        padding-bottom: 0.25rem;
      }
    }
    .prev-polls-list {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0.25rem;
      padding-top: 0.5rem;
      .poll-item {
        display: grid;
        grid-template-columns: 70% auto;
        grid-gap: 0.5rem;
        align-items: center;
        border-bottom: var(--border);
        &:last-child {
          border-bottom: 0;
        }
        .poll-title {
          font-weight: 400;
          font-size: 0.875rem;
          font-family: var(--font-heading);
        }
        .local-options {
          display: grid;
          grid-template-columns: auto auto;
          grid-gap: 0.25rem;
          button {
            font-size: 0.875rem;
            padding: 0.25rem;
            border: none;
            width: 100%;
            height: 100%;
            font-weight: 300;
            background-color: var(--white-hover);
            color: var(--muted-strong);
            margin: 0;
            padding: 0.5rem 0.875rem;
            &:hover {
              background-color: var(--btn-background-color);
              transform: none;
              box-shadow: none;
            }
            &.continue {
              display: grid;
              grid-template-columns: auto 18px;
              align-items: center;
              padding: 0.5rem 0.875rem;
            }
          }
        }

        @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
          grid-template-columns: 60% 1fr;
          .local-options {
            button {
              padding: 0.5rem;
              &.continue {
                padding: 0.5rem;
              }
            }
          }
        }
      }
    }

    .group-embed {
      .block-embed {
        width: 100%;
        padding: 1rem 0;
        textarea {
          display: block;
          width: 100%;
          margin: 0 auto;
          border-radius: var(--border-radius);
          border: var(--border);
          padding: 0.5rem;
          font-size: 0.85rem;
          color: var(--text-color);
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          height: 5.5rem;
          &:disabled {
            background-color: var(--white);
          }
        }
      }
    }

    .checkbox-opt {
      padding: 0.5rem 0;
      .option-check-wrapper {
        padding: 0;
        label {
          margin: 0;
        }
      }
    }
    .select-opt {
      padding-bottom: 0.5rem;
      .lang-dropdown__single-value {
        width: 100%;
      }
      .lang-dropdown__control {
        min-height: auto;
        height: 2.5rem;
        .lang-dropdown__indicator-separator {
          display: none;
        }
        .lang-dropdown__dropdown-indicator {
          padding: 0 4px;
        }
      }

      .lang-dropdown__value-container {
        height: 100%;
        .lang-dropdown__input {
          input {
            height: inherit;
          }
        }
      }
    }
    .button-opt {
      padding-bottom: 0.5rem;
      button {
        padding: 0.5rem 1.5rem;
        border: var(--border);
        background-color: var(--btn-background-color);
        outline: none;
        border-radius: var(--border-radius);
        color: var(--btn-text-color);
        &:hover {
          background-color: var(--btn-background-color-hover);
        }
        &.btn-danger {
          color: var(--white);
          background-color: var(--red);
          &:hover {
            background-color: var(--red-hover);
          }
        }
      }
    }
    .publisher-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
      @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
        grid-template-columns: 1fr;
      }
      .premium-settings {
        border-radius: var(--border-radius);
        background-color: var(--warning-background);
        border: 2px solid var(--warning);
        padding: 1rem;
        .inner-header {
          font-weight: 500;
          font-size: 1.15rem;
          font-family: var(--font-heading);
          ${'' /* padding-bottom: 0.25rem; */}
          text-align: center;
          padding: 0.5rem;
          h2,
          h3 {
            margin: 0;
          }
        }
      }
    }
    .input-question {
      width: 100%;
      input,
      textarea {
        display: block;
        width: 100%;
        margin: 0 auto;
        border-radius: var(--border-radius);
        border: var(--border);
        padding: 0.75rem;
        font-size: 1.1rem;
        color: var(--text-color);
        ${({ errorState }) =>
          errorState &&
          css`
            border-color: var(--danger);
          `}
      }
      input {
        height: var(--input-height);
      }
      textarea {
        height: var(--textarea-height);
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    width: 100%;
    min-width: auto;
    .card-header {
      padding-top: 2rem;
      padding-bottom: 2rem;
      padding-left: var(--mobile-padding-sizes);
      padding-right: var(--mobile-padding-sizes);
    }
    .card-body {
      .form-header {
        padding: 0.5rem var(--mobile-padding-sizes);
        .header-actions {
          ${'' /* text-align: end; */}
          margin-right: -1rem;
        }
      }
    }
    .new-page-wrapper {
      padding: 2rem var(--mobile-padding-sizes);
    }
    .new-question-wrapper {
      padding: 1rem var(--mobile-padding-sizes);
      padding-bottom: 3rem;
    }
    .settings-body,
    .results-body {
      padding: 2rem var(--mobile-padding-sizes);
      padding-bottom: 2rem;
      .share-block {
        .share-content {
          .share-embed {
            textarea {
              height: 5rem;
            }
          }
        }
      }
    }
  }
`;

export const StageNavigator = styled.div`
  width: 100%;
  padding: 1rem var(--desktop-padding-sizes);
  display: grid;
  grid-gap: 0.25rem;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  button {
    height: 8px;
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--blue-background-light);
    transition: 0.2s all;
    &:hover {
      background-color: var(--blue-hover);
      transform: translateY(-2px);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    }
    &[data-active='true'],
    &.active {
      background-color: var(--blue);
      transform: translateY(-1px);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
      &:hover {
        background-color: var(--blue-hover);
      }
      &:focus {
        outline: none;
      }
    }
    &:disabled {
      background-color: var(--btn-disabled-color);
      cursor: default;
      &:hover {
        transform: none;
        box-shadow: none;
      }
      &[data-active='true'] {
        background-color: var(--muted);
        transform: translateY(-1px);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    padding: 1rem var(--mobile-padding-sizes);
  }
`;

export const FooterCTANavigation = styled.div`
  position: relative;
  align-items: center;
  padding: 1rem var(--desktop-padding-sizes);
  position: sticky;
  bottom: 0;
  background-color: var(--white);
  z-index: 5;
  border-top: var(--border);
  button {
    font-size: 1.15rem;
    border: var(--border);
    border-radius: var(--border-radius);
    background-color: var(--blue);
    color: var(--white);
    box-shadow: 2px 2px 8px 0 rgba(29, 36, 86, 0.03);
    transition: 0.2s all;
    height: var(--item-height);
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0.25rem 0.75rem 0.25rem 1.25rem;
    i {
      display: flex !important;
    }
    &:hover {
      background-color: var(--blue-hover);
      ${'' /* transform: translateY(-1px); */}
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    }
    &:disabled {
      background-color: var(--muted);
      transform: none;
      box-shadow: inherit;
    }
  }
  .question-helper {
    text-align: center;
    font-size: smaller;
    display: block;
    margin-top: 0.5rem;
    color: var(--muted-strong);
  }

  .next-btn {
    text-align: right;
    display: inherit;
    display: contents;
  }
  .center-btn {
    text-align: center;
    button {
      width: 100%;
      i {
        margin-left: 5px;
        transition: 0.3s all ease;
      }
      &:hover {
        i {
          transform: translateX(4px);
        }
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    padding: 1rem var(--mobile-padding-sizes);
  }
`;
export const FooterNavigator = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 0.25rem;
  align-items: center;
  padding: 1rem var(--desktop-padding-sizes);
  position: sticky;
  bottom: 0;
  background-color: var(--white);
  z-index: 5;
  border-top: var(--border);
  &:after {
    position: absolute;
    content: '';
    height: 20px;
    width: 100%;
    top: -21px;
    left: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 25%,
      rgba(255, 255, 255, 0.7) 50%
    );
    z-index: -1;
  }

  .progress-navigator {
    display: flex;
    gap: 0.25rem;
    flex-wrap: wrap;
    button {
      width: 1.5rem;
      height: 0.35rem;
      background-color: var(--btn-background-color-hover);
      border: none;
      border-radius: var(--border-radius);
      display: inline-block;
      transition: background-color 200ms;
      &:hover {
        background-color: var(--blue-hover);
        transform: translateY(-1px);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
      }
      &[data-active='true'],
      &.active {
        background-color: var(--blue);
        transform: translateY(-1px);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
        &:hover {
          background-color: var(--blue-hover);
        }
        &:focus {
          outline: none;
        }
      }
      &:disabled {
        background-color: var(--btn-background-color-hover);
        &:hover {
          transform: none;
          box-shadow: none;
        }
        &[data-active='true'] {
          background-color: var(--blue);
          transform: translateY(-1px);
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
        }
      }
      &:focus {
        outline: none;
      }
    }
  }
  .back-btn {
    text-align: right;
    button {
      border: 0;
      background-color: transparent;
      font-size: 1.15rem;
      color: var(--blue);
      &:hover {
        text-decoration: underline;
      }
      &:disabled {
        color: var(--muted-strong);
      }
    }
  }
  .next-btn {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    button {
      font-size: 1.15rem;
      border: var(--border);
      border-radius: var(--border-radius);
      background-color: var(--blue);
      color: var(--white);
      box-shadow: 2px 2px 8px 0 rgba(29, 36, 86, 0.03);
      transition: 0.2s all;
      height: var(--item-height);
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0.25rem 0.75rem 0.25rem 1.25rem;
      i {
        display: flex !important;
      }
      &:hover {
        background-color: var(--blue-hover);
        transform: translateY(-1px);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
      }
      &:disabled {
        background-color: var(--muted-strong);
        transform: none;
        box-shadow: inherit;
      }
      .updating {
        display: flex !important;
        justify-content: center;
        align-items: center;
        animation: ${rotate} 2s linear infinite;
      }
    }
  }
  .clear-poll-action {
    button {
      display: grid;
      grid-template-columns: 18px 1fr;
      align-items: center;
      grid-gap: 0.25rem;
      width: 100%;
      height: 100%;
      font-weight: 300;
      background-color: var(--white);
      color: var(--muted-strong);
      margin: 0;
      padding: 0.5rem 1rem 0.5rem 0.875rem;
      &:hover {
        ${'' /* background-color: var(--btn-background-color-hover); */}
        background-color: var(--btn-background-color);
        transform: none;
        box-shadow: none;
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    padding: 1rem var(--mobile-padding-sizes);
  }
`;

export const StyledPoweredBy = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  .powered-icon {
    width: 15px;
    height: 15px;
    position: relative;
    img {
      object-fit: contain;
    }
  }
  a {
    color: var(--blue);
    font-weight: 500;
  }
`;

export const StyledFooter = styled.div`
  border-top: 1px solid var(--border-color);
  padding: 4rem;
  .footer-nav {
    display: grid;
    grid-template-columns: 40% 1fr 1fr 1fr;
    padding: 1rem 0;
    .logo-col {
      display: grid;
      grid-template-columns: auto;
      align-items: center;
      .footer-logo {
        width: 200px;
        position: relative;
        height: 50px;
        img {
          object-fit: contain;
        }
      }
      .footer-lead {
        padding: 1.5rem 0;
        padding-right: 1.5rem;
        max-width: 350px;
        font-size: 1.15rem;
        color: var(--muted-strong);
        line-height: 1.5rem;
        font-family: var(--font-heading);
      }
    }
    .footer-menu {
      .menu-header {
        font-family: var(--font-heading);
        font-weight: 500;
        font-size: 1.5rem;
        padding-bottom: 1rem;
      }
      .menu-list {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 0.875rem;
        a {
          font-weight: 400;
          transition: 0.2s all;
          &:hover {
            color: var(--blue);
          }
        }
      }
    }
  }
  .footer-copyright {
    margin-top: 4rem;
    text-align: center;
    font-size: 0.875rem;
    color: var(--muted-strong);
    font-family: var(--font-heading);
    a {
      text-decoration: underline;
      transition: 0.2s all;
      &:hover {
        color: var(--indigo);
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.lg}) {
    .footer-nav {
      grid-template-columns: 1fr 1fr 1fr;
      .logo-col {
        grid-column-start: 1;
        grid-column-end: 4;
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    .footer-nav {
      grid-template-columns: 1fr 1fr 1fr;
      .logo-col {
        grid-column-start: 1;
        grid-column-end: 4;
      }
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    .footer-nav {
      grid-template-columns: 1fr;
      .logo-col {
        grid-column-start: unset;
        grid-column-end: unset;
      }
    }
  }
`;

export const StyledBaseCTA = styled.div`
  border-top: 1px solid var(--border-color);
  background-color: var(--muted);
  padding: 6rem 4rem;
  display: grid;
  grid-template-columns: 65% auto;
  align-items: center;
  grid-gap: 2rem;
  .cta-lead {
    font-size: 3rem;
    font-weight: 700;
    font-family: var(--font-heading);
  }
  .cta-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 0.5rem;
    button {
      font-size: 1.175rem;
      width: 100%;
      border: none;
      outline: none;
      border-radius: var(--border-radius);
      padding: 1rem 0.5rem;
      padding-left: 1rem;
      color: var(--btn-text-color);
      background-color: var(--btn-background-color);
      display: grid;
      grid-template-columns: 28px 1fr;
      grid-gap: 0.2rem;
      align-items: center;
      i {
        display: flex !important;
      }
      &:hover {
        background-color: var(--btn-background-color-hover);
      }
      &.link {
        grid-template-columns: 1fr;
        background-color: var(--white);
        text-decoration: underline;
        &:hover {
          background-color: var(--white);
          color: var(--blue);
        }
      }
      &.btn-orange {
        color: var(--white);
        background-color: var(--orange);
        &:hover {
          background-color: var(--orange-hover);
        }
      }
      &.btn-white {
        background-color: var(--white);
        &:hover {
          color: var(--blue);
          background-color: var(--white);
        }
      }
    }
  }

  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    grid-template-columns: 1fr;
    text-align: center;
    .cta-lead {
      font-size: 2.5rem;
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.xl}) {
    .cta-actions {
      grid-template-columns: 1fr;
    }
  }
`;

export const StyledBigCTA = styled.div`
  border-top: 1px solid var(--border-color);
  background-color: var(--muted);
  ${'' /* background-image: linear-gradient(45deg, var(--muted), var(--yellow)); */}
  padding: 6rem 4rem;
  display: grid;
  grid-template-rows: 1fr 6fr 3fr;
  align-items: center;
  grid-gap: 1rem;
  place-content: center;
  text-align: center;
  .cta-heading {
    font-size: 2rem;
    font-weight: 600;
    font-family: var(--font-heading);
    color: var(--pink);
    /* Fallback: Set a background color. */
    background-color: var(--pink);
    background-image: linear-gradient(45deg, var(--pink), var(--orange));
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
  .cta-lead {
    font-family: var(--font-heading);
    font-size: 6.5rem;
    line-height: 6rem;
    margin: 0;
    padding: 1rem 0;
    font-weight: 800;
    letter-spacing: 2px;
    /* Fallback: Set a background color. */
    background-color: var(--blue);
    background-image: linear-gradient(45deg, var(--blue), var(--indigo));
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
  .cta-actions {
    align-items: center;
    grid-gap: 0.5rem;
    display: grid;
    place-content: center;
    button {
      font-size: 1.175rem;
      border: none;
      outline: none;
      border-radius: var(--border-radius);
      padding: 1.5rem 3.5rem;
      padding-left: 3rem;
      color: var(--btn-text-color);
      background-color: var(--btn-background-color);
      display: grid;
      grid-template-columns: 28px 1fr;
      grid-gap: 0.2rem;
      align-items: center;
      i {
        display: flex !important;
      }
      &:hover {
        background-color: var(--btn-background-color-hover);
      }
      &.link {
        grid-template-columns: 1fr;
        background-color: var(--white);
        text-decoration: underline;
        &:hover {
          background-color: var(--white);
          color: var(--blue);
        }
      }
      &.btn-orange {
        color: var(--white);
        background-color: var(--orange);
        &:hover {
          background-color: var(--orange-hover);
        }
      }
      &.btn-white {
        background-color: var(--white);
        &:hover {
          color: var(--blue);
          background-color: var(--white);
        }
      }
    }
  }

  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
    padding: 4rem 2rem;
    grid-template-rows: auto auto auto;
    grid-gap: 1.5rem;
    .cta-lead {
      font-size: 5rem;
      line-height: 4rem;
    }
  }
  @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.sm}) {
    grid-template-rows: auto auto auto;
    grid-gap: 1.5rem;
    .cta-lead {
      font-size: 4rem;
      line-height: 3.5rem;
    }
    .cta-actions {
      button {
        padding: 1.5rem 2.5rem;
        padding-left: 2rem;
      }
    }
  }
`;

export const StyledCookieBar = styled.div`
  .cookie-alert-bar {
    padding: 0.875rem 1.5rem;
    width: 100%;
    border-top: var(--border);
    background-color: var(--white);
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0px;
    bottom: 0px;
    z-index: 999;
    @media (max-width: ${({ theme: { breakpoints } }) => breakpoints?.md}) {
      display: block;
    }
  }
  .bar-content {
    flex: 1 0 300px;
    font-size: 0.875rem;
    ${'' /* margin: 1rem 0.5rem; */}
    margin: 1rem 1.5rem;
  }
  .bar-buttons {
    display: grid;
    ${'' /* grid-gap: 2rem; */}
    ${'' /* grid-template-columns: 1fr 2fr; */}
    grid-template-columns: 1fr;
    padding: 0 3rem;
    button {
      width: 100%;
      padding: 0.65rem 0.875rem;
    }
    .btn-primary {
      color: var(--white);
      background-color: var(--blue);
      &:hover {
        background-color: var(--blue-hover);
      }
    }
    .btn-reject {
      color: var(--muted-strong);
      background-color: var(--white);
      padding: 0.25rem 0.5rem;
    }
  }
`;

export const StyledSkeleton = styled.span`
  display: block;
  width: 100%;
  border-radius: 5px;
  ${'' /* --accents- 2:  #eaeaea; */}
  background-image: linear-gradient(
    270deg,
    var(--white-hover),
    var(--muted),
    var(--muted),
    var(--white-hover)
  );
  background-size: 400% 100%;
  animation: ${loadingSkeleton} 4s ease-in-out infinite;
`;

export const Collapsible = styled.div`
  opacity: ${props => (props.open ? '1' : '0')};
  max-height: ${props => (props.open ? '100%' : '0')};
  overflow: hidden;
  ${'' /* padding: ${props => (props.open ? '1rem' : '0.5rem 1rem')}; */}
  padding-bottom: 1rem;
  transition: all 0.3s ease-in-out;
`;

export const AvatarLetter = styled.div`
  width: ${({ width }) => width || 35}px;
  height: ${({ height }) => height || 35}px;
  background: var(--blue);
  color: var(--white-hover);
  font-size: ${({ large }) => (large ? 3 : 1)}rem;
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import { keyframes } from 'styled-components';

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
export const loadingSkeleton = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

export const borderDance = keyframes`
  0% {
    background-position: 0px 0px, 100% 100%, 0px 100%, 100% 0px;
  }
  100% {
    background-position: 100% 0px, 0px 100%, 0px 0px, 100% 100%;
  }
`;
